<template>
  <div class="wrapper">
    <nav>
      <a
        @click="activeName = 'qrcode'"
        :class="{ active: activeName === 'qrcode' }"
        href="javascript:"
        ><img
          v-if="activeName === 'account'"
          src="../assets/banner/qrcode.png"
        />
        <img v-else src="../assets/banner/qrcodeActive.png" /> 扫码登录</a
      >
      <a
        @click="activeName = 'account'"
        :class="{ active: activeName === 'account' }"
        href="javascript:"
        ><img
          v-if="activeName === 'account'"
          src="../assets/banner/noteActive.png"
        />
        <img v-else src="../assets/banner/note.png" /> 短信登录</a
      >
    </nav>
    <!-- 即将放置账号登录表单组件, 此处使用div先占个位 -->
    <div v-if="activeName === 'account'">
      <div class="form">
        <el-form
          ref="ruleFormRef"
          :model="ruleForm"
          status-icon
          :rules="rules"
          label-width="20px"
          class="demo-ruleForm"
        >
          <div>
            <el-form-item class="inputplus" prop="phoneNumber">
              <el-input
                class="from-style1"
                placeholder="请输入手机号"
                v-model.number="ruleForm.phoneNumber"
              />
            </el-form-item>
          </div>
          <div>
            <el-row :gutter="20">
              <el-col :span="16">
                <el-form-item class="inputplus" prop="imgCode">
                  <el-input
                    class="from-style1-1"
                    style="height: 38px; font-size: 13px"
                    placeholder="请输入图形验证码"
                    maxlength="4"
                    v-model.number="ruleForm.imgCode"
                    id="autofouce"
                  />
                </el-form-item>
              </el-col>
              <el-col
                :span="8"
                style="position: relative; left: -11px; top: 10px"
              >
                <el-image @click="reCodeImg" :src="codeImg"></el-image
              ></el-col>
            </el-row>
          </div>
          <div class="from-style2">
            <div>
              <el-form-item class="inputplus" prop="validateCode">
                <el-input
                  class="from-style1"
                  placeholder="请输入验证码"
                  v-model.number="ruleForm.validateCode"
                  autocomplete="off"
                  maxlength="6"
                />
              </el-form-item>
              <el-button
                v-if="isCheck"
                class="code"
                :class="{ disabled: isActive }"
                :disabled="isActive"
                @click="getCode"
              >
                {{ isActive ? `剩余${count}秒` : "发送验证码" }}
              </el-button>
              <el-button v-else @click="isrefsend" class="code"
                >重新发送</el-button
              >
            </div>
          </div>
          <el-button class="btn" type="submit" @click="submitForm(ruleFormRef)"
            >立即登录</el-button
          >
          <div>
            <div class="form-item2">
              <!-- <el-checkbox
                @change="checkIS"
                style="position: relative; top: 2px; right: 3px"
                v-model="checked"
                size="large"
              /> -->
              <el-image
                style="margin-right: 8px; position: relative; top: 4px"
                v-if="!checked"
                @click="checkIS(true)"
                v-model="checked"
                :src="require('./img/isticked.png')"
              />
              <el-image
                style="margin-right: 8px; position: relative; top: 4px"
                v-else
                @click="checkIS(false)"
                v-model="checked"
                :src="require('./img/tickedActice.png')"
              />
              <span style="color: #aaaaaa">我已阅读并同意</span>
              <a style="color: #333333" href="javascript:"
                ><router-link :to="`/putsomeing?page=1`" target="_blank"
                  >《用户协议》</router-link
                ></a
              >
              <span style="color: #aaaaaa">和</span>
              <a style="color: #333333" href="javascript:"
                ><router-link :to="`/putsomeing?page=2`" target="_blank"
                  >《隐私协议》</router-link
                ></a
              >
            </div>
            <div
              v-show="showtime"
              class="skip"
              style="color: var(--el-color-danger)"
            >
              <i>请阅读并同意《用户协议》《隐私协议》</i>
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <!-- 二维码登录 -->
    <div class="qrcode-box" v-if="activeName === 'qrcode'">
      <div style="height: 250px">
        <img
          v-if="miniimg"
          :src="miniimg"
          style="width: 200px; height: 200px; margin-top: 20px"
        />
        <img
            v-else
            src="./img/loading1.gif"
            alt=""
            style="width: 200px; height: 200px; margin-top: 20px"
        />
      </div>
      <p>
        <img src="../assets/banner/saoyisao.png" />
        请打开微信扫一扫，扫码授权登录
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUpdated, reactive } from "vue";
import { FormInstance, FormRules } from "element-plus";
import { useRouter } from "vue-router";
import {
  getImageCode,
  wxacode,
  getSendCode,
  UserLogin,
  wechatUserInfo,
  getUserInfo,
} from "@/api/login/index";
import { ElMessage } from "element-plus";
import emitter from "@/utils/eventBus";
import {checkSafe} from "@/api/Home";

// const redirectUrl = ref("https://testshop.iningmei.com")
const redirectUrl = ref(window.location.origin);
// const redirectUrl = ref("http://172.16.5.58:80")
const ruleFormRef = ref();
const isCheck = ref(true);
const showtime = ref(false);
const checked = ref(false);
const validataOk = ref(false);
const validataImgOk = ref(false);
const isActive = ref(false);
const uuid = ref();
const codeImg = ref();
const leavefor = ref();
const address = ref("data:image/gif;base64,");
const uniqueIdentify = ref();
const ruleForm = reactive({
  phoneNumber: "",
  imgCode: "",
  validateCode: "",
});

onMounted(() => {
  Code();
  getWxacode();
});
onUpdated(() => {
  clearData();
});
const getWxacode = () => {
  var timers = setInterval(() => {
    emitter.on("closeTimer", (Fn) => {
      if (Fn.closeTime) {
        clearInterval(timers);
      }
    });
    wechatUserInfo(uniqueIdentify.value).then((res) => {
      if (res.code == 200 && res.data.token) {
        let token = res.data.token;
        localStorage.setItem("token", token);
        uniqueIdentify.value = "";
        clearInterval(timers);
        getInfo();
      } else if (res.code == 403) {
        ElMessage({
          message: "登录超时",
          type: "error",
        });
        setTimeout(function () {
          router.go(0);
        }, 500);
      }
    });
  }, 2000);
  wxacode().then((res) => {
    miniimg.value = address.value + res.data.qrCode;
    uniqueIdentify.value = res.data.uniqueIdentify;
  });
};
const miniimg = ref();
const router = useRouter();
const getInfo = () => {
	getUserInfo().then((res) => {
		if (res.code == 200) {
			localStorage.setItem("user", JSON.stringify(res.data.user));
			ElMessage({
				message: "登录成功",
				type: "success",
			});
			setTimeout(function () {
				router.go(0);
			}, 500);
		} else {
			ElMessage({
				message: res.message,
				type: "error",
			});
		}
	}).then(() => {
		// 访客记录
		const url = window.location.href
		let referer = document.referrer
		if (referer == '') {
			referer = '直接访问'
		} else if (referer.indexOf('?') != -1) {
			referer = referer.substring(0, referer.indexOf('?'))
		}

		const query = {
			client: "PC",
			page: url,
			source: referer
		}
		checkSafe(query)
	})
}
const reCodeImg = () => {
  Code();
};
const clearData = (formEl) => {
  ruleFormRef.value?.resetFields();
};
const Code = () => {
  getImageCode().then((res) => {
    codeImg.value = address.value + res.data.img;
    uuid.value = res.data.uuid;
  });
};
const phoneNumber = (rule, value, callback) => {
  if (!value) {
    return callback(new Error("请输入手机号!"));
  }
  setTimeout(() => {
    if (!Number.isInteger(value)) {
      callback(new Error("请输入数字!"));
    } else {
      if (value.toString().length < 11) {
        callback(new Error("请输入11位数手机号!"));
      } else {
        validataOk.value = true;
        callback();
      }
    }
  }, 200);
};
const validateCode = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("请输入验证码!"));
  } else {
    if (value.toString().length < 6) {
      callback(new Error("请输入6位数验证码!"));
    } else {
      callback();
    }
  }
};
const imgCode = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("请输入图形验证码!"));
  } else {
    if (value.toString().length < 4) {
      callback(new Error("请输入4位数图形验证码!"));
    } else {
      validataImgOk.value = true;
      callback();
    }
  }
};
const rules = reactive({
  phoneNumber: [
    { validator: phoneNumber, trigger: "blur" },
    {
      pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
      message: "请输入正确的手机号码",
      trigger: "blur",
    },
  ],
  imgCode: [{ validator: imgCode, trigger: "blur" }],
  validateCode: [{ validator: validateCode, trigger: "blur" }],
});
const submitForm = (formEl) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    if (valid) {
      if (checked.value) {
        showtime.value = false;
        let data = {
          username: ruleForm.phoneNumber,
          uuid: uuid.value,
          credential: ruleForm.validateCode,
	        client: 'PC'
        };
        let option = {
          message: "message",
        };
        UserLogin(data, option)
          .then((res) => {
            if (res.code == 200) {
              let token = res.data.token;
              localStorage.setItem("token", token);
              if (token) {
                getInfo(token);
              }
            } else {
              ElMessage({
                message: res.message,
                type: "error",
              });
            }
          })
          .catch((error) => {
            if (error.err.response.data.code == 400) {
              ElMessage({
                message: error.err.response.data.message,
                type: "error",
              });
            }
          });
      } else {
        showtime.value = true;
      }
    } else {
      console.log("error submit!");
      return false;
    }
  });
};
const checkIS = (res) => {
  if (res) {
    showtime.value = false;
    checked.value = true;
  } else {
    showtime.value = true;
    checked.value = false;
  }
};
const isrefsend = () => {
  getCode();
};
const SendPhoneFn = () => {
  let data = {
    phoneNumber: ruleForm.phoneNumber,
    code: ruleForm.imgCode,
    uuid: uuid.value,
  };
  ifpassEnter.value = 2;
  getSendCode(data).then((res) => {
    if (res.code == 200) {
      isCheck.value = true;
      ifpassEnter.value = 1;
      isActive.value = true;
      var timer = setInterval(function () {
        count.value--;
        if (count.value == 0) {
          clearInterval(timer);
          isCheck.value = false;
          count.value = 60;
        }
      }, 1000);
    } else {
      ElMessage({
        message: res.message,
        type: "error",
      });
      document.getElementById("autofouce").focus();
      ifpassEnter.value = 1;
    }
  });
};
const count = ref(60);
const ifpassEnter = ref(1);
const getCode = () => {
  if (validataOk.value && validataImgOk.value) {
    var telreg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
    if (
      !telreg.test(ruleForm.phoneNumber) ||
      ruleForm.phoneNumber.toString().length != 11
    ) {
      return { data: false, msg: "手机号码格式不正确" };
    } else {
      if (ifpassEnter.value == 1) {
        SendPhoneFn();
      }
    }
  } else {
    ElMessage.error("请输入正确的手机号或图形验证码");
  }
};

const activeName = ref("qrcode");
</script>

<style scoped lang="less">
.wrapper {
  width: 380px;
  background: #fff;
  min-height: 400px;
  transform: translate3d(100px, 0, 0);

  .qrcode-box {
    text-align: center;
    padding-top: 10px;
    p {
      margin-top: 20px;
      a {
        color: @xtxColor;
        font-size: 16px;
      }
    }
  }
  nav {
    height: 55px;
    border-bottom: 1px solid #f5f5f5;
    display: flex;
    padding: 0 40px;
    text-align: right;
    align-items: center;
    a {
      flex: 1;
      line-height: 2;
      display: inline-block;
      font-size: 18px;
      position: relative;
      &:first-child {
        border-right: 1px solid gainsboro;
        text-align: left;
      }
      &.active {
        color: #ce1200;
        font-weight: bold;
      }
    }
  }
}
.form {
  padding: 0 20px;
  .topfont {
    width: 391px;
    height: 18px;
    font-size: 17px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  .form-item :first-child {
    margin-top: 20px;
  }

  &-item {
    margin-bottom: 18px;
    .input {
      position: relative;
      width: 476px;
      height: 44px;
      border-radius: 5px;
      top: -40px;
      left: -80px;
      > i {
        width: 34px;
        height: 34px;
        background: #cfcdcd;
        color: #fff;
        position: absolute;
        left: 1px;
        top: 1px;
        text-align: center;
        line-height: 34px;
        font-size: 18px;
      }
      input {
        padding-left: 16px;
        border: 1px solid #cfcdcd;
        height: 36px;
        border-radius: 5px;
        line-height: 36px;
        width: 100%;
        &.error {
          border-color: @priceColor;
        }
        &.active,
        &:focus {
          border-color: @xtxColor;
        }
      }

      .code.disabled {
        cursor: wait;
      }
    }

    > .error {
      position: absolute;
      font-size: 12px;
      line-height: 28px;
      color: @priceColor;
      i {
        font-size: 14px;
        margin-right: 2px;
      }
    }
  }
}
.from-style1 {
  font-size: 13px;
  width: 358px;
  height: 38px;
  margin-top: 6px;
  border-radius: 5px;
  ::v-deep .el-input__inner {
    padding-left: 10px !important;
  }
}
.from-style1-1 {
  ::v-deep .el-input__inner {
    padding-left: 10px !important;
  }
}
.inputplus {
  position: relative;
  left: -10px;
  top: 10px;
}
.code {
  position: absolute;
  right: 38px;
  top: 188px;
  text-align: center;
  line-height: 34px;
  font-size: 14px;
  color: #ce1200;
  width: 90px;
  height: 34px;
  cursor: pointer;
  background-color: #fff;
  border: none;
}
.code.disabled {
  color: grey;
}
.btn {
  border: none;
  background-color: #ce1200;
  height: 44px;
  font-size: 16px;
  width: 318px;
  border-radius: 5px;
  position: absolute;
  bottom: 80px;
  left: 30px;
  color: #fff;
  .el-button:focus,
  .el-button:hover {
    color: #ffffff;
    border-color: #ce1200;
    background-color: #ce1200;
    outline: 0;
  }
}
.form-item2 {
  position: absolute;
  bottom: 20px;
  left: 30px;
}
.skip {
  position: absolute;
  bottom: 0px;
  left: 45px;
}
.input1 {
  border: 1px solid #cfcdcd;
  height: 38px;
  line-height: 38px;
  width: 68%;
  border-radius: 5px;
  position: relative;
  .input2 {
    position: absolute;
    border: 1px solid #cfcdcd;
    height: 36px;
    line-height: 36px;
    width: 90px;
    left: 228px;
    top: -0.0625rem;
    border-radius: 5px;
  }
}
</style>
