import request from "@/utils/request";

// 根据id查询详情
export function MessageId(id) {
  return request.get(`/order/message/getById/${id}`);
}

// 查询详情
export function MessageDetails({ pageNum, pageSize, messageType }) {
  return request.get("/order/message/pageListMessage", {
    pageNum,
    pageSize,
    messageType,
  });
}

// 消息分类列表 （红点）
export function MessageRed() {
  return request.get("/order/message/listMessageType");
}

// 顶部图片
export function MessageImg(machineCode) {
  return request.get(`/goods/banner/queryBanner/${machineCode}`);
}
